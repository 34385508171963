
import { defineComponent, inject, PropType } from 'vue'
import { required } from '@vuelidate/validators'

import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import { MovementRepositoryKey } from '@/data/injectables'
import { CreateMovementParams, MovementForm } from '@/data/movement/types'
import { UserLogData } from '@/data/auth/types'

import BlockUI from '@/components/BlockUI.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import FormField from '@/components/FormField.vue'
import SelectFish from '@/components/selects/SelectFish.vue'
import SelectPond from '@/components/selects/SelectPond.vue'
import UserLog from '@/components/UserLog.vue'
import { ScrollPosition } from '@/data/types'

export default defineComponent({
  components: { NavBar, FormField, SelectFish, SelectPond, BlockUI, UserLog },
  props: {
    movementId: {
      type: Number as PropType<number>,
      default: null,
    },
    fishId: {
      type: Number as PropType<number>,
      default: null,
    },
    pondId: {
      type: Number as PropType<number>,
      default: null,
    },
    action: {
      type: String as PropType<string>,
      default: null,
    },
    scrollPos: {
      type: String as PropType<ScrollPosition>,
      default: 'top',
    },
  },
  setup: () => {
    const movementRepository = inject(MovementRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)

    return {
      v$: useVuelidate(),
      movementRepository,
      handleError,
      ...toast,
    }
  },
  data() {
    return {
      saving: false,
      deleting: false,
      loading: false,
      form: {
        datetime: new Date(),
        fish: this.fishId,
        pond: this.pondId,
        action: this.action || 'IN',
      } as MovementForm,
      userLog: {} as UserLogData,
      submitted: false,
    }
  },
  computed: {
    editing(): boolean {
      return !!this.movementId
    },
    title(): string {
      return this.editing ? 'Editar movimento' : 'Criar movimento'
    },
    busy(): boolean {
      return this.loading || this.saving || this.deleting
    },
    hasQueryString(): boolean {
      return Boolean(this.pondId && this.fishId && this.action)
    },
  },
  validations() {
    return {
      form: {
        datetime: {
          required,
        },
        fish: {
          required,
        },
        pond: {
          required,
        },
        quantity: {
          required,
        },
      },
    }
  },
  created() {
    if (this.editing) {
      this.loadFormAndUserLog()
    }
  },
  mounted() {
    if (this.scrollPos === 'bottom') {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth',
        })
      }, 500)
    }
  },
  methods: {
    async handleSave(): Promise<void> {
      this.submitted = true
      if (this.v$.$invalid) return

      this.saving = true
      if (this.editing) {
        await this.updateMovement()
      } else {
        await this.createMovement()
      }
      this.saving = false
    },
    async handleDelete(): Promise<void> {
      this.$confirm.require({
        message: 'Confirma a deleção do movimento?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.deleteMovement()
        },
      })
    },
    async updateMovement(): Promise<void> {
      try {
        await this.movementRepository?.updateMovement(
          this.movementId,
          this.form
        )
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Movimento editado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async createMovement(): Promise<void> {
      try {
        await this.movementRepository?.createMovement(
          this.form as CreateMovementParams
        )
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Movimento criado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async deleteMovement(): Promise<void> {
      try {
        this.deleting = true
        await this.movementRepository?.deleteMovement(this.movementId)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Movimento deletado com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.deleting = false
      }
    },
    async loadFormAndUserLog(): Promise<void> {
      try {
        this.loading = true
        const { createdBy, modifiedBy, ...form } =
          (await this.movementRepository?.getMovement(this.movementId)) || {}
        this.form = form
        this.userLog = {
          createdBy,
          modifiedBy,
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
  },
})
